import { DefaultSeoProps } from 'next-seo';

const title = 'Bezel - Find your next watch';

const description =
  'Shop for thousands of the most collectible watches online. Find Rolex, Cartier, Patek Philippe, Panerai, IWC, and more. All of our watches are authenticated in-house by our team of specialists and watchmakers with decades of experience in luxury watches.';

const preconnectOrigins = [
  'https://auth.split.io',
  'https://sdk.split.io',
  'https://cdn.segment.com',
  'https://www.googletagmanager.com',
  'https://o1379274.ingest.sentry.io'
];

const config: DefaultSeoProps = {
  title,
  description,
  openGraph: {
    title,
    description,
    images: [
      {
        url: 'https://storage.googleapis.com/public-us-standard-bezel_prod/static/open_graph_banner.jpg',
        alt: 'Bezel Site Global Image',
        type: 'image/png'
      }
    ],
    siteName: 'Bezel'
  },
  twitter: {
    site: '@getbezel',
    cardType: 'summary_large_image'
  },
  additionalMetaTags: [
    {
      name: 'apple-itunes-app',
      content: 'app-id=1586195658'
    },
    {
      name: 'facebook-domain-verification',
      content: 'y3169t57mf2vnblr4y9toxw79wl0ai'
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0'
    }
  ],
  additionalLinkTags: [
    {
      rel: 'shortcut icon',
      href: '/favicon.ico'
    },
    ...preconnectOrigins.map(origin => ({
      rel: 'preconnect',
      href: origin
    }))
  ]
};

export default config;
