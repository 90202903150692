import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

import { clientApiGet } from 'lib/api_helper';
import { PurchaseRequest, User } from 'types';

export const useExpiredSheet = (request: PurchaseRequest, user?: User) => {
  const [isExpiredSheetVisible, setIsExpiredSheetVisible] = useState(false);
  const isExpiredSheetSeen = useRef(false);

  const { data: requests } = useSWR<PurchaseRequest[]>(
    request.status === 'EXPIRED' && user?.buyerProfile?.id
      ? `/api/purchaseRequests/getRequests?buyerProfile[]=${user.buyerProfile.id}&model[]=${request.model.id}&status[]=LIVE`
      : null,
    clientApiGet
  );

  useEffect(() => {
    if (!isExpiredSheetSeen.current && request.status === 'EXPIRED' && requests && requests.length === 0) {
      setIsExpiredSheetVisible(true);
      isExpiredSheetSeen.current = true;
    }
  }, [request.status, requests, requests?.length]);

  return { isExpiredSheetVisible, setIsExpiredSheetVisible };
};
