import { useEffect } from 'react';

export const useRecaptcha = () =>
  useEffect(() => {
    const url =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'www.google.com/recaptcha/enterprise.js?render=' +
      process.env.NEXT_PUBLIC_RECAPTCHA_SESSION_KEY +
      '&waf=session';

    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.async = true;
    s.defer = true;
    s.src = url;
    const x = document.getElementsByTagName('script')[0];

    x.parentNode?.insertBefore(s, x);
  }, []);
