import { FC, PropsWithChildren } from 'react';
import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';
import classNames from 'classnames';

import { Button } from 'components/design-system/Button';
import { Slideout, SlideoutCommonProps } from 'components/design-system/Slideout';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/Auctions.module.scss';

import { AuthLogo } from './AuthLogo';

export type AuthSlideoutProps = {
  contentClassName?: string;
  onClick?: () => void;
} & SlideoutCommonProps &
  PropsWithChildren;

export const AuthSlideout: FC<AuthSlideoutProps> = ({ children, contentClassName = '', onClick, ...props }) => (
  <Slideout
    {...props}
    title
    customFooter={
      <div className='d-flex flex-column align-items-center'>
        <a className='w-100' style={{ maxWidth: 325 }} href={pageRoutes.signUp}>
          <Button
            roundedClassName='border-radius-46'
            className='px-4 py-3 justify-content-between riforma-regular w-100'
            variant='success'
            onClick={onClick}
          >
            <span className='fs-16px'>Create a Bezel Account</span>
            <HiArrowNarrowRight size={20} className='position-absolute top-50 end-0 mx-3 translate-middle' />
          </Button>
        </a>
        <div className='pb-3' />
        <div onClick={onClick} className='fs-14px text-center w-100'>
          <a href={pageRoutes.login} className={classNames('opacity-70 cursor-pointer', styles.logInText)}>
            I already have a Bezel account. Log in
          </a>
        </div>
      </div>
    }
  >
    <div className='riforma-regular d-flex flex-column justify-content-between align-items-center text-primary'>
      <AuthLogo />
      <div className='fs-48px fs-lg-56px line-height-56px line-height-lg-64px text-center'>
        <div>Unlock</div>
        <div>
          <span className='text-success bradford-regular-italic'>the best </span>
          <span>of</span>
        </div>
        <div>Bezel</div>
      </div>
      <div className={classNames('text-primary pt-4 justify-content-between d-flex flex-column', contentClassName)}>
        {children}
      </div>
    </div>
  </Slideout>
);
