import { createContext, useContext } from 'react';
import { SplitFactory as SplitFactoryJS } from '@splitsoftware/splitio';
import { type SplitKey, type TreatmentsWithConfig } from '@splitsoftware/splitio/types/splitio';
import { SplitClient, SplitFactoryProvider, useSplitTreatments } from '@splitsoftware/splitio-react';

import { useBezelUser } from 'context/bezelUser';

import * as splitNames from './consts';

type SplitNames = keyof typeof splitNames;

const DEFAULT_SPLIT_KEY = 'user';

const sdkConfig = {
  core: {
    authorizationKey: process.env.NEXT_PUBLIC_SPLIT_CLIENT_API_KEY,
    key: DEFAULT_SPLIT_KEY
  }
};

interface SplitFactoryContextProviderProps {
  children: React.ReactElement;
  ssrTreatments: TreatmentsWithConfig;
}

const splitFactory = SplitFactoryJS(sdkConfig);

export const getSSRTreatments = async () => {
  const client = splitFactory.client();

  await client.ready();
  const allSplitNames = Object.values(splitNames);

  return client.getTreatmentsWithConfig(DEFAULT_SPLIT_KEY, allSplitNames);
};

const SsrTreatmentsContext = createContext<TreatmentsWithConfig>({});

export const SplitFactoryContextProvider: React.FC<SplitFactoryContextProviderProps> = ({
  children,
  ssrTreatments
}) => {
  const { user } = useBezelUser();

  return (
    <SsrTreatmentsContext.Provider value={ssrTreatments}>
      <SplitFactoryProvider config={sdkConfig}>
        <SplitClient splitKey={user?.id as SplitKey}>{children}</SplitClient>
      </SplitFactoryProvider>
    </SsrTreatmentsContext.Provider>
  );
};

const defaultTreatment = { treatment: 'control', config: null };

const useGetTreatmentWithConfig = <T,>(splitName: SplitNames): { treatment: string; config: T } => {
  const ssrTreatments = useContext(SsrTreatmentsContext);
  const { treatments: clientTreatments, isReady } = useSplitTreatments({ names: [splitName] });
  const treatments = isReady ? clientTreatments : ssrTreatments;
  const { treatment, config } = treatments?.[splitName] || defaultTreatment;

  return { treatment, config: config ? JSON.parse(config || '') : config };
};

export const useGetTreatmentState = (splitName: SplitNames) => {
  const { treatment } = useGetTreatmentWithConfig(splitName);
  const isDisabled = treatment === 'off';
  const isEnabled = treatment === 'on';
  const isLoading = treatment === 'control';

  return { isEnabled, isLoading, isDisabled };
};
