import { FC, useCallback } from 'react';
import { Offcanvas } from 'react-bootstrap';
import classNames from 'classnames';

import { AnyQuestionsHelper } from 'components/listing_detail/AnyQuestionsHelper';

import { headerHeight, SlideoutTestIds } from './constants';
import styles from './Slideout.module.scss';
import { SlideoutHeader } from './SlideoutHeader';
import { SlideoutTitle } from './SlideoutTitle';
import { SlideoutProps } from './types';
import { useHandleBack } from './useHandleBack';

import { Button } from '../Button';

export const Slideout: FC<SlideoutProps> = ({
  isDark,
  title,
  customHeader,
  isOpen,
  children,
  closeModal,
  submit,
  isTitleFixed,
  footerClassName,
  bodyClassName = '',
  bodyRef,
  onClickPrevious,
  cancel,
  large,
  className,
  showAnyQuestions,
  customFooter,
  canClose = true,
  ...restSlideoutProps
}) => {
  let header: JSX.Element | null = null;

  const onHide = useCallback(() => closeModal?.(), [closeModal]);

  useHandleBack(onHide);

  if (customHeader) {
    header = customHeader({ onClickPrevious });
  } else if (title) {
    header = (
      <SlideoutHeader
        onHide={onHide}
        canClose={canClose}
        onClickPrevious={onClickPrevious}
        isDark={isDark}
        isTitleFixed={isTitleFixed}
      />
    );
  }

  return (
    <Offcanvas
      data-testid={SlideoutTestIds.container}
      placement='end'
      show={isOpen}
      onHide={onHide}
      className={classNames(large ? styles.slideoutLarge : styles.slideout, styles.common, className, {
        'bg-darkTheme': isDark
      })}
      {...restSlideoutProps}
    >
      {header}
      <Offcanvas.Body className={classNames('p-4 pt-2', bodyClassName)} ref={bodyRef}>
        {title && (
          <div style={{ paddingTop: title && !isTitleFixed && !customHeader ? headerHeight - 8 : 0 }}>
            <SlideoutTitle className='mb-2' isDark={!!isDark} title={title} />
          </div>
        )}
        {children}
      </Offcanvas.Body>
      {(submit || cancel || showAnyQuestions || customFooter) && (
        <div
          className={classNames(
            'w-100 px-4 mt-2 d-flex flex-column',
            showAnyQuestions ? 'pb-3 pb-lg-4' : styles.footer,
            footerClassName
          )}
        >
          {customFooter}
          {submit && (
            <Button
              onClick={submit.onClick}
              variant={submit.variant || 'primary'}
              loading={submit.loading}
              className={classNames('w-100 py-3 fs-14px', submit.className)}
              textClassname={submit.textClassname}
              disabled={submit.disabled || submit.loading}
              data-testid={submit.testId}
            >
              {submit.title || 'Submit'}
            </Button>
          )}
          {cancel && (
            <Button
              onClick={cancel.onClick}
              variant={cancel.variant || 'light-outline'}
              loading={cancel.loading}
              className={classNames('w-100 py-3 fs-14px', cancel.className, { 'mt-2': submit })}
              textClassname={cancel.textClassname}
              disabled={cancel.disabled || cancel.loading}
              data-testid={cancel.testId}
            >
              {cancel.title || 'Cancel'}
            </Button>
          )}
          {showAnyQuestions && (
            <div className='d-flex mt-2 justify-content-center'>
              <AnyQuestionsHelper />
            </div>
          )}
        </div>
      )}
    </Offcanvas>
  );
};
