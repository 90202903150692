import { useEffect } from 'react';

import { LookingForWatchSlideout } from 'components/purchaseRequests/Slideouts/LookingForWatchSlideout';
import { SHOULD_SHOW_REQUESTS_ONBOARDING } from 'consts/storageKeys';
import { useModal } from 'context/modal/ModalContext';
import { getFromStorage, removeFromStorage } from 'lib/storageHelpers';

export const useShowRequestOnboarding = (isLoading: boolean) => {
  const { closeModal, openModal } = useModal();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const shouldShowRequestsOnboarding = getFromStorage(SHOULD_SHOW_REQUESTS_ONBOARDING) === 'true';

    if (shouldShowRequestsOnboarding) {
      openModal(<LookingForWatchSlideout closeModal={closeModal} />);
      removeFromStorage(SHOULD_SHOW_REQUESTS_ONBOARDING);
    }
  }, [closeModal, openModal, isLoading]);
};
