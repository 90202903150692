import { FC } from 'react';
import classNames from 'classnames';

import { COLORS } from 'consts';

import styles from './pill.module.scss';

export const PillTestIds = {
  container: 'pill-container'
};

export type PillProps = {
  onClick?: () => void;
  title: string;
  className?: string;
  color?: keyof typeof colorSchema;
};

const colorSchema = {
  green: 'linear-gradient(269deg, #07745B 0%, #076D74 100%)',
  success: COLORS.success,
  warning: COLORS.yellow,
  secondary: COLORS.primary50
} as const;

export const Pill: FC<PillProps> = ({ onClick, title, className, color = 'green' }) => (
  <div
    data-testid={PillTestIds.container}
    role={onClick ? 'button' : undefined}
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();
      onClick?.();
    }}
    className={classNames(styles.pill, className || 'px-3 py-2 riforma-regular text-white border-radius-46', {
      'pointer-events-none': !onClick
    })}
    style={{
      background: colorSchema[color]
    }}
  >
    {title}
  </div>
);
