import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import useSWR from 'swr';

import { clientApiGet } from 'lib/api_helper';
import { ListingCondition, ListingStat, Model, PurchaseRequest, PurchaseRequestOffer } from 'types';

import { useBezelUser } from './bezelUser';

type PurchaseRequestContextProps = {
  condition?: ListingCondition;
  setCondition: (val: ListingCondition) => void;
  model?: Model;
  setModel: (val: Model) => void;
  year?: number;
  setYear: Dispatch<SetStateAction<number | undefined>>;
  price?: number;
  purchaseRequests: PurchaseRequest[];
  setPrice: (val: number) => void;
  isRequestsLoading: boolean;
  resetRequest: () => void;
  isUrgencySlideoutVisible: boolean;
  setIsUrgencySlideoutVisible: (val: boolean) => void;
  minAvailablePrice?: number;
  requestOffers: PurchaseRequestOffer[];
  refreshData: () => void;
};

const PurchaseRequestContext = createContext<PurchaseRequestContextProps | null>(null);

type PurchaseRequestContextProviderProps = PropsWithChildren;

export const PurchaseRequestContextProvider: React.FC<PurchaseRequestContextProviderProps> = ({ children }) => {
  const [condition, setCondition] = useState<ListingCondition>();
  const [model, setModel] = useState<Model>();
  const [year, setYear] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [isUrgencySlideoutVisible, setIsUrgencySlideoutVisible] = useState(false);
  const { user } = useBezelUser();

  const {
    data: purchaseRequests = [],
    isLoading: isRequestsLoading,
    mutate: refreshRequests
  } = useSWR<PurchaseRequest[]>(
    model ? `/api/purchaseRequests/getRequests?model[]=${model.id}&status[]=LIVE&expand[]=model` : null,
    clientApiGet
  );
  const { data: stats } = useSWR<ListingStat>(
    model?.id ? `/api/listings/stats?modelIds=${model.id}` : null,
    clientApiGet
  );
  const purchaseRequestsIds = useMemo(
    () => purchaseRequests.map(({ id }) => '&purchaseRequest[]=' + id).join(''),
    [purchaseRequests]
  );
  const { data: requestOffers = [], mutate: refreshOffers } = useSWR<PurchaseRequestOffer[]>(
    model?.id && user?.sellerProfile?.id && purchaseRequestsIds.length > 0
      ? `/api/purchaseRequests/requestOffer?model[]=${model.id}&sellerProfile[]=${user.sellerProfile.id}&expand[]=listing.model&expand[]=listing.accessories${purchaseRequestsIds}`
      : null,
    clientApiGet
  );

  const resetRequest = () => {
    setCondition(undefined);
    setYear(undefined);
    setPrice(undefined);
  };

  const refreshData = useCallback(() => {
    refreshRequests();
    refreshOffers();
  }, [refreshOffers, refreshRequests]);

  const providerValue = useMemo(
    () => ({
      condition,
      setCondition,
      model,
      setModel,
      year,
      setYear,
      price,
      setPrice,
      purchaseRequests,
      isRequestsLoading,
      resetRequest,
      isUrgencySlideoutVisible,
      setIsUrgencySlideoutVisible,
      minAvailablePrice: stats?.minPrice,
      refreshData,
      requestOffers
    }),
    [
      condition,
      model,
      year,
      requestOffers,
      price,
      purchaseRequests,
      isRequestsLoading,
      isUrgencySlideoutVisible,
      stats?.minPrice,
      refreshData
    ]
  );

  return <PurchaseRequestContext.Provider value={providerValue}>{children}</PurchaseRequestContext.Provider>;
};

export const usePurchaseRequest = () => {
  const purchaseRequest = useContext(PurchaseRequestContext);

  if (!purchaseRequest) {
    throw new Error('PurchaseRequestContext used outside of PurchaseRequestContext.Provider!');
  }

  return purchaseRequest;
};
