import { FC } from 'react';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import classNames from 'classnames';

import styles from './Slideout.module.scss';

type SlideoutCloseButtonProps = {
  onClick: () => void;
  isDark?: boolean;
  testID?: string;
  className?: string;
};

export const SlideoutCloseButton: FC<SlideoutCloseButtonProps> = ({ isDark, onClick, testID, className }) => (
  <div
    style={{ zIndex: 2 }}
    role='button'
    onClick={onClick}
    className={classNames(styles.iconContainer, styles.closeIcon, className, {
      'text-light bg-dark-grey': isDark,
      'text-primary bg-light': !isDark
    })}
    data-testid={testID}
  >
    <MdClose size={28} />
  </div>
);
