import { useAnalytics } from 'context/analytics';
import { useBezelUser } from 'context/bezelUser';
import { logGTMSearchInteraction, logSearchInteraction } from 'lib/events';

interface UseSearchAnalyticsParams {
  query: string;
}

export const useSearchAnalytics = ({ query }: UseSearchAnalyticsParams) => {
  const analytics = useAnalytics();
  const { user } = useBezelUser();

  const trackSearchInteraction = () => {
    logSearchInteraction(analytics, query);
  };

  const dataLayerSearchInteraction = () => {
    logGTMSearchInteraction(query, user);
  };

  const triggerSearchInteractionEvents = () => {
    trackSearchInteraction();
    dataLayerSearchInteraction();
  };

  return {
    triggerSearchInteractionEvents
  };
};
