import TagManager from 'react-gtm-module';

import { Analytics } from 'context/analytics';
import { Listing, ListingImageType, PricingModels, User } from 'types';

import { fireTrackEvent } from './helpers';

type SegmentPayload = {
  brandName: string;
  modelId: number;
  modelName: string;
  referenceNumber: string;
  listingId: number;
  condition: 'PREOWNED' | 'UNWORN';
  manufactureYear?: number;
  imageUrl: string;
  currentPrice: string;
  maxBidPrice: string;
  offersAllowed?: boolean;
  activePricingModel: PricingModels;
  currency: 'USD';
};

export const logMaxBidSubmitted = (analytics: Analytics, listing: Listing, newMaxBid: number) => {
  const payload: SegmentPayload = {
    listingId: listing.id,
    modelId: listing.model.id,
    modelName: listing.model.displayName,
    referenceNumber: listing.model.referenceNumber,
    brandName: listing.model?.brand?.displayName,
    condition: listing.condition,
    imageUrl: listing.images.find(i => i.type === ListingImageType.FRONT)?.image.optimizedUrl || '',
    currentPrice: ((listing.auctionInfo?.highestBid?.priceCents || 0) / 100.0).toFixed(2),
    maxBidPrice: (newMaxBid / 100.0).toFixed(2),
    offersAllowed: listing.activePricingModel === 'AUCTION' ? false : listing.offersAllowed,
    activePricingModel: listing.activePricingModel,
    currency: 'USD'
  };

  fireTrackEvent(analytics, 'Max Bid Submitted', payload);
};

type GTMPayload = {
  brandName: string;
  modelId: number;
  modelName: string;
  referenceNumber: string;
  listingId: number;
  condition: 'PREOWNED' | 'UNWORN';
  manufactureYear?: number;
  imageUrl: string;
  currentPrice: string;
  maxBidPrice: string;
  activePricingModel: PricingModels;
  currency: 'USD';
};

export const logGTMMaxBidSubmitted = (listing: Listing, newMaxBid: number, user?: User) => {
  const payload: GTMPayload = {
    listingId: listing.id,
    modelId: listing.model.id,
    modelName: listing.model.displayName,
    referenceNumber: listing.model.referenceNumber,
    brandName: listing.model?.brand?.displayName,
    condition: listing.condition,
    imageUrl: listing.images.find(i => i.type === ListingImageType.FRONT)?.image.optimizedUrl || '',
    currentPrice: ((listing.auctionInfo?.highestBid?.priceCents || 0) / 100.0).toFixed(2),
    maxBidPrice: (newMaxBid / 100.0).toFixed(2),
    activePricingModel: listing.activePricingModel,
    currency: 'USD',
    ...(user && {
      email: user.email,
      userId: user.id
    })
  };

  TagManager.dataLayer({
    dataLayer: {
      event: 'submitted_max_bid',
      ...payload
    }
  });
};
