import { FC, PropsWithChildren, ReactNode } from 'react';

import { Slideout, SlideoutProps } from 'components/design-system/Slideout';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { Model } from 'types';

import { PurchaseRequestHeader } from './PurchaseRequestHeader';

type PurchaseRequestSlideout = {
  subtitle?: ReactNode;
  modelFromProps?: Model;
  extraTitleComponent?: ReactNode;
} & SlideoutProps &
  PropsWithChildren;

export const PurchaseRequestSlideout: FC<PurchaseRequestSlideout> = ({
  children,
  subtitle,
  extraTitleComponent,
  modelFromProps,
  ...props
}) => {
  const { closeModal } = useModal();
  const { model } = usePurchaseRequest();

  return (
    <Slideout
      {...props}
      customHeader={() => (
        <>
          <PurchaseRequestHeader onClose={closeModal} model={modelFromProps || model} {...props} />
          {extraTitleComponent}
        </>
      )}
    >
      <div className='d-flex flex-column align-items-center mt-3'>
        {subtitle && <div className='opacity-60 riforma-regular px-3 mb-4 text-center'>{subtitle}</div>}
        {children}
      </div>
    </Slideout>
  );
};
