import { FC, useEffect, useRef } from 'react';

import { BottomSheet } from 'components/design-system/BottomSheet';
import { Button } from 'components/design-system/Button';
import { useAnalytics } from 'context/analytics';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { logRequestPromptRenewal } from 'lib/events';
import { ListingCondition, PurchaseRequest } from 'types';

import { RequestHoldSlideout } from '../RequestHoldSlideout';
import { SelectModelSlideout } from '../SelectModelSlideout';

export type ExpiredBottomSheetProps = {
  onClose: () => void;
  request: PurchaseRequest;
};

export const ExpiredBottomSheet: FC<ExpiredBottomSheetProps> = ({ onClose, request }) => {
  const { closeModal, openModal } = useModal();
  const { setPrice, setModel, setCondition, setYear, resetRequest } = usePurchaseRequest();
  const isLoggedViewedRequestRenewal = useRef(false);
  const analytics = useAnalytics();

  useEffect(() => {
    if (isLoggedViewedRequestRenewal.current) {
      return;
    }
    isLoggedViewedRequestRenewal.current = true;
    logRequestPromptRenewal(analytics);
  }, [analytics]);

  const openResubmitRequest = () => {
    if (request.unwornRestriction === 'REQUIRE') {
      setCondition(ListingCondition.Unworn);
    }
    if (request.yearRestriction) {
      setYear(request.yearRestriction);
    }

    setModel(request.model);
    setPrice(request.requestedPriceCents);
    openModal(<RequestHoldSlideout closeModal={closeModal} />, true);
  };

  const onMakeAnotherRequest = () => {
    resetRequest();
    openModal(<SelectModelSlideout closeModal={closeModal} />);
  };

  return (
    <BottomSheet
      className='pb-6 pb-md-7'
      onClose={onClose}
      title={
        <div className='fs-20px mb-4 mt-2 riforma-medium text-primary text-center letter-spacing-007'>
          Your purchase request has expired
        </div>
      }
    >
      <div className='riforma-regular letter-spacing-006 text-secondary text-center mb-4 px-3'>
        Purchase requests are only live for 7 days and yours has expired. This means it will no longer be available to
        sellers.
      </div>
      <Button onClick={openResubmitRequest} className='w-100 py-3' variant='primary'>
        Resubmit request
      </Button>
      <Button onClick={onMakeAnotherRequest} className='w-100 py-3 mt-2' variant='secondary'>
        Submit another
      </Button>
    </BottomSheet>
  );
};
