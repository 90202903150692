import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useHandleBack = (callback: () => void) => {
  const { beforePopState } = useRouter();

  useEffect(() => {
    // close slideout on browser back button click
    beforePopState(() => {
      callback();

      return true;
    });
  }, [beforePopState, callback]);
};
