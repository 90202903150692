import { FC } from 'react';

export const StatusMessageTestIds = {
  container: 'status-message-container'
};
export type StatusMessageProps = {
  type:
    | 'expired'
    | 'closed'
    | 'waitingForOffers'
    | 'waitingForCheckout'
    | 'sellerAwaitingResponse'
    | 'sellerPendingReview';
};
export const StatusMessage: FC<StatusMessageProps> = ({ type }) => {
  let text = '';

  if (type === 'waitingForOffers') {
    text = 'Waiting for sellers to respond to your request...';
  } else if (type === 'closed') {
    text = 'Purchase request closed';
  } else if (type === 'expired') {
    text = 'Purchase request expired';
  } else if (type === 'waitingForCheckout') {
    text = 'Waiting for buyer to checkout';
  } else if (type === 'sellerAwaitingResponse') {
    text = 'Waiting for buyer to respond to your offer';
  } else if (type === 'sellerPendingReview') {
    text =
      'Offer is marked as “Pending” because it is going through an expedited review by our team before it’s sent to the prospective buyer';
  }

  if (!text) {
    return null;
  }

  return (
    <div
      data-testid={StatusMessageTestIds.container}
      className='px-3 mt-4 fs-14px opacity-60 text-center riforma-regular'
    >
      {text}
    </div>
  );
};
