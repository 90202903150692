import useSWR from 'swr';

import { clientApiGet } from 'lib/api_helper';
import { PurchaseRequest, PurchaseRequestOffer } from 'types';

export const useRequestOffers = (request: PurchaseRequest, requestOffer?: PurchaseRequestOffer) => {
  const { data: requestOffers = [], isLoading } = useSWR<PurchaseRequestOffer[]>(
    request && request.id && !requestOffer
      ? `/api/purchaseRequests/requestOffer?purchaseRequest[]=${request.id}&expand[]=listing.model`
      : null,
    clientApiGet
  );

  return { requestOffers, isLoading };
};
